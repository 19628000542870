import React from 'react'
import PropTypes from 'prop-types'

import { StyledButtonLabel, StyledButton } from './styles'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import { BUTTON_SIZES } from '../../../constants/buttonSizes'

const Button = ({ children, submit, decor, ...rest }) => (
  <StyledButton type="submit" {...{ decor, submit, ...rest }}>
    <StyledButtonLabel>{children}</StyledButtonLabel>
  </StyledButton>
)

export default Button

Button.defaultProps = {
  btnType: BUTTON_TYPES.primary,
  btnSize: BUTTON_SIZES.medium,
  decor: true,
  submit: false,
}

Button.propTypes = {
  btnType: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
  btnSize: PropTypes.oneOf(Object.keys(BUTTON_SIZES)),
  decor: PropTypes.bool,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
