import styled, { css } from 'styled-components'

import {
  buttonBase,
  primary,
  secondary,
  simple,
  dark,
  medium,
  inverted,
} from '../styles'
import { BUTTON_SIZES } from '../../../constants/buttonSizes'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'

const SIZE_MAP = {
  [BUTTON_SIZES.medium]: medium,
}

const TYPE_MAP = {
  [BUTTON_TYPES.primary]: primary,
  [BUTTON_TYPES.secondary]: secondary,
  [BUTTON_TYPES.dark]: dark,
  [BUTTON_TYPES.simple]: simple,
  [BUTTON_TYPES.inverted]: inverted,
}

const StyledButtonLabel = styled.span``

const StyledButton = styled.button`
  ${buttonBase}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

    
  ${({ btnSize }) =>
    SIZE_MAP[btnSize] &&
    css`
      ${SIZE_MAP[btnSize]}
    `}

  ${({ btnType }) =>
    TYPE_MAP[btnType] &&
    css`
      ${TYPE_MAP[btnType]}
    `}

  ${({ submit }) =>
    submit &&
    css`
      font-size: 1.125rem;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      letter-spacing: 0.32px;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    `}
`

export { StyledButtonLabel, StyledButton }
