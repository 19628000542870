import React from 'react'
import { graphql } from 'gatsby'

import Inner from '../components/Inner'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Layout from '../components/Layout'
import LatestNews from '../components/LatestNews/LatestNews'
import { HEADER_TYPES } from '../constants/headerTypes'
import { BG_TYPES } from '../constants/bgTypes'
import mapNewsToList from '../common/queries/mapNewsToList'
import { Heading7 } from '../components/Typography/Typography'

const SingleTagsTemplate = ({ data }) => {
  const { tag } = data.wp
  const seoData = tag.seo
  let news

  if (tag.posts.edges) {
    news = mapNewsToList(tag.posts.edges)
  }

  return (
    <Layout headerType={HEADER_TYPES.alt} headerDarkLogo>
      <SEO title={seoData?.title} data={seoData} />
      <Container
        bg={BG_TYPES.lightGrey}
        mt={['4.875rem', '', '0']}
        pt={['1.875rem', '', '2.625rem']}
        pb={['2.875rem']}
      >
        <Inner>
          <Container>
            <Heading7 as="h1" textAlign="center">
              {tag.name}
            </Heading7>
          </Container>
          {news && (
            <Container mt={['2.5rem', '', '3.375rem']}>
              <LatestNews news={news} hasLoadMore />
            </Container>
          )}
        </Inner>
      </Container>
    </Layout>
  )
}

export default SingleTagsTemplate

export const pageQuery = graphql`
  query($tagId: ID!) {
    wp {
      tag(id: $tagId, idType: ID) {
        id
        name
        seo {
          ...seoFragment
        }
        posts {
          edges {
            node {
              ...postFragment
            }
          }
        }
      }
    }
  }
`
